/**
 * @generated SignedSource<<bd65189f5de46038be5c8c5f51b22a30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessModelSettingsRootQuery$variables = Record<PropertyKey, never>;
export type AccessModelSettingsRootQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessModelSettingsPage_query" | "AccessModelTab_awsAccessRoles">;
};
export type AccessModelSettingsRootQuery = {
  response: AccessModelSettingsRootQuery$data;
  variables: AccessModelSettingsRootQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessModelSettingsRootQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccessModelSettingsPage_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccessModelTab_awsAccessRoles"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccessModelSettingsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "whoami",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UrlConfig",
            "kind": "LinkedField",
            "name": "UrlConfig",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docs",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AWSAccessRoles",
        "kind": "LinkedField",
        "name": "awsAccessRoles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountDiscoveryRoleARN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryRoleARN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readOnlyRoleARN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remediationPolicyRoleARN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "samplePrimaryTrust",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee88d044fb2a200bd7d4ba7c29862106",
    "id": null,
    "metadata": {},
    "name": "AccessModelSettingsRootQuery",
    "operationKind": "query",
    "text": "query AccessModelSettingsRootQuery {\n  ...AccessModelSettingsPage_query\n  ...AccessModelTab_awsAccessRoles\n}\n\nfragment AccessModelSettingsPage_query on Query {\n  ...PageWithBreadCrumbs_query\n}\n\nfragment AccessModelTab_awsAccessRoles on Query {\n  ...GatewayRolesForm_awsAccessRoles\n  awsAccessRoles {\n    externalID\n    primaryRoleARN\n    samplePrimaryTrust\n  }\n}\n\nfragment BreadCrumbsHeader_query on Query {\n  whoami {\n    email\n    displayName\n  }\n}\n\nfragment GatewayRolesForm_awsAccessRoles on Query {\n  awsAccessRoles {\n    accountDiscoveryRoleARN\n    primaryRoleARN\n    readOnlyRoleARN\n    remediationPolicyRoleARN\n    samplePrimaryTrust\n  }\n}\n\nfragment PageWithBreadCrumbs_query on Query {\n  ...BreadCrumbsHeader_query\n}\n"
  }
};

(node as any).hash = "61a404d39b550fe62789dd0866e936ed";

export default node;
