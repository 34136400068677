import { useUserContext } from "app/contexts";
import { useHasRequiredPermission } from "app/hooks";
import { ADMIN_PERMISSIONS } from "app/utils/consts";
import { isEqual } from "app/utils/permissions";

import type { Permission, useConfig } from "app/contexts";

interface BaseNavItems {
  label: string;
  id: string;
  isExternalLink?: boolean;
  mainPath?: string;
  permissionRequired?: {
    object: string;
    action: string;
  };
}

interface NavItemsLeafNode extends BaseNavItems {
  target: string;
  children?: never;
}

interface NavItemsBranchNode extends BaseNavItems {
  children: NavItem[];
  target?: never;
}

export type NavItem = NavItemsBranchNode | NavItemsLeafNode;

function filterItemsByPermissions(
  items: NavItem[],
  permissions: Permission[],
): NavItem[] {
  const isAllowed = (item: NavItem) => {
    const { permissionRequired } = item;
    return permissionRequired
      ? permissions.some((permission) =>
          isEqual(permission, permissionRequired),
        )
      : true;
  };

  return items.filter((item) => {
    item.children = item.children?.filter((child: NavItem) => isAllowed(child));
    return isAllowed(item);
  });
}

interface Dashboard {
  label: string;
  id: string;
  key: string;
  target: string;
}

export function useNavItems(
  enabledDashboards: Dashboard[],
  config: ReturnType<typeof useConfig>,
) {
  const permissions = useUserContext();
  const hasPermission = useHasRequiredPermission();
  const isAdmin = hasPermission(ADMIN_PERMISSIONS);

  const settingsAndTools = [
    ...(isAdmin
      ? [
          {
            label: "Settings",
            id: "settings",
            children: [
              ...(config?.dev_flags.includes("rbac")
                ? [
                    {
                      label: "Access Control",
                      id: "settings-access-control",
                      target: "/settings/access-control",
                    },
                  ]
                : []),
              ...(config?.dev_flags.includes("admin-settings")
                ? [
                    {
                      label: "Access Model",
                      id: "settings-access-model",
                      target: "/settings/access-model",
                    },
                  ]
                : []),
              {
                label: "Integrations",
                id: "integrations",
                target: "/settings/integrations",
              },
              {
                label: "Policy repositories",
                id: "settings-repositories",
                target: "/settings/repositories",
              },
              {
                label: "Users",
                id: "settings-users",
                target: "/settings/users",
              },
            ],
          },
        ]
      : []),
    {
      label: "Stacklet API",
      target: "/api",
      isExternalLink: false,
      id: "gql-api",
    },
  ];

  const navItems: NavItem[] = [
    {
      label: "Overview",
      id: "overview",
      children: [
        {
          label: "Cloud",
          id: "cloud",
          target: "/overview",
        },
        {
          label: "Stacklet Activity",
          target: "/activity",
          id: "activity",
        },
        ...enabledDashboards,
        {
          label: "Deployed Policies",
          id: "deployed-policies",
          target: "/policies",
        },
      ],
    },
    {
      label: "Cloud Ops",
      id: "cloudops",
      children: [
        {
          label: "Resource Matches",
          target: "/cloudops/resource-matches",
          id: "cloudops-resource-matches",
        },
        {
          label: "Resource Explorer",
          target: "/cloudops/resources",
          id: "cloudops-resources",
        },
      ],
    },
    {
      label: "Admin",
      id: "admin",
      children: [
        {
          label: "Accounts",
          target: "/admin/accounts",
          id: "admin-accounts",
        },
        {
          label: "Account Groups",
          target: "/admin/account-groups",
          id: "admin-account-groups",
        },
        {
          label: "Bindings",
          id: "admin-bindings",
          target: "/admin/bindings",
        },
        {
          label: "Policies",
          id: "admin-policies",
          target: "/admin/policies",
        },
        {
          label: "Policy Collections",
          id: "admin-policy-collections",
          target: "/admin/policy-collections",
        },
      ],
    },
    {
      label: "Notifications",
      id: "notifications",
      permissionRequired: ADMIN_PERMISSIONS,
      children: [
        {
          label: "Overview",
          id: "overview",
          target: "/notifications/overview",
        },
        {
          label: "Settings",
          id: "settings",
          target: "/notifications/settings",
        },
      ],
    },
    ...settingsAndTools,
  ];

  const items = filterItemsByPermissions(navItems, permissions);

  return items;
}
