import { type useConfig } from "../../../contexts/ConfigContext";

export function getSettingsBreadcrumbs(config: ReturnType<typeof useConfig>) {
  return [
    { label: "Settings" },
    {
      tabs: [
        ...(config?.dev_flags.includes("rbac")
          ? [
              {
                label: "Access Control",
                id: "settings-access-control",
                target: "/settings/access-control",
              },
            ]
          : []),
        ...(config?.dev_flags.includes("admin-settings")
          ? [
              {
                label: "Access Model",
                target: "/settings/access-model",
              },
            ]
          : []),
        { label: "Integrations", target: `/settings/integrations` },
        { label: "Policy Repositories", target: `/settings/repositories` },
        { label: "Users", target: `/settings/users` },
      ],
    },
  ];
}
