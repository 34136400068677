import { Suspense } from "react";

import Box from "@mui/material/Box";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useSearchParams } from "react-router";

import { type AccessControlSettingsRootQuery } from "./__generated__/AccessControlSettingsRootQuery.graphql";
import { AccessControlSettingsPage } from "./AccessControlSettingsPage";

export function AccessControlSettingsRoot() {
  const [searchParams] = useSearchParams({});
  const view = searchParams.get("view");
  const tabValue = view || "sso-groups";

  const data = useLazyLoadQuery<AccessControlSettingsRootQuery>(
    graphql`
      query AccessControlSettingsRootQuery {
        ...AccessControlSettingsPage_query
      }
    `,
    {},
  );

  return (
    <AccessControlSettingsPage queryRef={data}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Suspense fallback={null}>
          {tabValue === "sso-groups" ? <></> : null}
        </Suspense>
      </Box>
    </AccessControlSettingsPage>
  );
}
