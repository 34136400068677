import { createContext, useContext, useEffect, useState } from "react";

import { z } from "zod";

interface Props {
  children: React.ReactNode;
}

const ConfigSchema = z.object({
  analytics_configurable: z.boolean(),
  cloud_providers: z.array(z.enum(["aws", "azure", "gcp", "tencentcloud"])),
  dev_flags: z.array(
    z
      .enum([
        "new-cost",
        "initiatives",
        "teams",
        "jun0",
        "admin-settings",
        "rbac",
      ])
      .optional(),
  ),
  customer: z.string(),
  hotjar_enabled: z.boolean(),
  is_poc: z.boolean(),
  private_instance: z.boolean(),
  segment_write_key: z.string(),
  sentry_enabled: z.boolean(),
  limited_visibility: z.boolean(),
});

export type AppConfig = z.infer<typeof ConfigSchema>;

const ConfigContext = createContext<AppConfig | null>(null);

export async function fetchConfig(): Promise<AppConfig> {
  const response = await fetch(import.meta.env.VITE_CONFIG_ENDPOINT);

  if (!response.ok) {
    const message = `An error has occurred fetching application configuration: ${response.status}`;
    throw new Error(message);
  }

  const config = await response.json();
  try {
    ConfigSchema.parse(config);
  } catch (err) {
    const messages = (err as z.ZodError).issues
      .map((issue) => `\n  * ${issue.path}: ${issue.message}`)
      .join("");
    console.error("Unable to parse config", config, messages);
  }

  return config;
}

export function ConfigProvider({ children }: Props): JSX.Element {
  const [config, setConfig] = useState<AppConfig | null>(null);

  useEffect(() => {
    const getConfig = async () => {
      const config = await fetchConfig().catch((error: Error) =>
        console.error(error),
      );
      if (config) {
        setConfig(config);
      }
    };

    getConfig();
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}

export const useConfig = () => {
  return useContext(ConfigContext);
};
