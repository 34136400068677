import type { PropsWithChildren } from "react";

import { graphql, useFragment } from "react-relay";

import { PageWithBreadCrumbs } from "app/components/layout/PageWithBreadCrumbs";
import { useConfig } from "app/contexts";

import { type AccessControlSettingsPage_query$key } from "./__generated__/AccessControlSettingsPage_query.graphql";
import { getSettingsBreadcrumbs } from "../utils/getSettingsBreadcrumbs";

interface Props extends PropsWithChildren {
  queryRef: AccessControlSettingsPage_query$key;
}

export function AccessControlSettingsPage({ queryRef, children }: Props) {
  const data = useFragment(
    graphql`
      fragment AccessControlSettingsPage_query on Query {
        ...PageWithBreadCrumbs_query
      }
    `,
    queryRef,
  );

  const config = useConfig();
  const breadcrumbs = getSettingsBreadcrumbs(config);

  return (
    <PageWithBreadCrumbs
      breadcrumbs={breadcrumbs}
      queryRef={data}
      title="Access Control"
    >
      {children}
    </PageWithBreadCrumbs>
  );
}
